<template>
  <b-table
    hover
    :busy="busy"
    :items="item.shipments"
    :fields="fieldsList"
    responsive
    :sticky-header="'calc(100vh - 330px)'"
  >

    <!-- Column: sender -->
    <template #cell(sender)="{ item }">
      <b-link :href="getCollectiveUrl(item.sender)" target="_blank">
        <div
          class="text-dark w-100 h-100 d-flex pl-1 ml-50 align-items-center"
          style="min-width: 150px"
        >
          <p class="mb-0">
            {{ getTranslatedName(item.sender) }}
          </p>
        </div>
      </b-link>
    </template>

    <!-- Column: receiver -->
    <template #cell(receiver)="{ item }">
      <b-link :href="getCollectiveUrl(item.receiver)" target="_blank">
        <div
          class="text-dark w-100 h-100 d-flex pl-1 ml-50 align-items-center"
          style="min-width: 150px"
        >
          <p class="mb-0">
            {{ getTranslatedName(item.receiver) }}
          </p>
        </div>
      </b-link>
    </template>

    <!-- Column: Reference -->
    <template #cell(ref)="{ item }">
      <div class="w-100 h-100 d-flex pl-1 ml-50 align-items-center">
        <p>{{ item.ref }}</p>
      </div>
    </template>

    <!-- Column: Freight Mode -->
    <template #cell(freightmode)="{ item }">
      <div
        class="w-100 h-100 d-flex pl-1 ml-50 align-items-center"
        style="min-width: 160px"
      >
        <p class="mb-0">
          {{ getFreightMode(item.freightMode) }}
        </p>
      </div>
    </template>

<!-- Column: Freight Incoterm -->
<template #cell(incoterm)="{ item }">
  <div
    class="w-100 h-100 d-flex pl-1 ml-50 align-items-center"
    style="min-width: 160px"
  >
    <p class="mb-0">
      {{ getIncoterm(item.freightPrepaidIncoterm, item.freightCollectIncoterm) }}
    </p>
  </div>
</template>

    <!-- Column: Issuing Date -->
    <template #cell(shippingdate)="{ item }">
      <div
        class="w-100 h-100 d-flex pl-1 ml-50 align-items-center"
        style="min-width: 160px"
      >
        <p class="mb-0">
          {{ getTransactionDate(item.shippingDate) }}
        </p>
      </div>
    </template>

    <!-- Column: Due Date -->
    <template #cell(deliverydate)="{ item }">
      <div
        class="w-100 h-100 d-flex pl-1 ml-50 align-items-center"
        style="min-width: 160px"
      >
        <p class="mb-0">
          {{ getTransactionDate(item.deliveryDate) }}
        </p>
      </div>
    </template>

    <!-- Column: User -->
    <template #cell(user)="{ item }">
      <b-media
        class="d-flex align-items-center pl-1 ml-25 mt-25"
        style="min-width: 150px"
      >
        <template>
          <b-link
            class="text-dark d-flex align-items-center"
            @click="fetchMembers(item.createdBy)"
          >
            <div>
              <user-avatar 
                v-if="item.createdBy"
                :user="item.createdBy" 
                size="24" 
              />
              <user-avatar 
                v-else
                :user="{}" 
                size="24" 
              />
            </div>
            <div class="ml-50">
              <p v-if="item.createdBy" class="font-weight-extrabold mb-0">
                {{ `${item.createdBy.name} ${item.createdBy.surname}` }}
              </p>
              <span v-else class="text-muted">---</span>
            </div>
          </b-link>
        </template>
      </b-media>
    </template>

    <!-- Column: Date Creation -->
    <template #cell(createdat)="{ item }">
      <div
        class="w-100 h-100 d-flex pl-1 ml-50 align-items-center"
        style="min-width: 160px"
      >
        <p class="mb-0">
          {{ getTransactionDate(item.createdAt) }}
        </p>
      </div>
    </template>

    <!-- Column: Status -->
    <template #cell(status)="{ item }">
      <div class="d-flex align-items-center">
        <b-badge
          v-if="item.status"
          pill
          :variant="getColor(item.status)"
          class="text-capitalize mb-0 ml-1 mt-75 check-button"
        >
          {{ getStatus(item.status) }}
        </b-badge>
        <p v-else class="text-muted mb-0 ml-2 pl-75 mt-75">---</p>
      </div>
    </template>

    <!-- Column: Actions -->
    <template #cell(actions)="{ item }">
      <div class="d-flex align-items-center">
        <!-- Edit -->
        <feather-icon icon="Edit2Icon" size="18" class="text-primary ml-2 cursor-pointer" @click="handleEdit(item)" disabled />
        <!-- Edit item modal -->
        <b-modal
          id="edit-shipment-form"
          v-model="isModalEditOpened"
          :title="$t('backoffice.shipments.edit')"
          :ok-title="$t('form.actions.save')"
          ok-only
          @ok="saveEditedTransaction()"
        >
          <div>Coming soon... work in progress.</div>
        </b-modal>

        <!-- Delete -->
        <feather-icon icon="Trash2Icon" size="18" class="text-primary cursor-pointer ml-1" @click="handleDelete(item)" />
        <!-- Delete item modal -->
        <unshare-item-modal
          v-model="isDeleteModalVisible"
          model-type="shipment"
          @remove-item="handleRemoveItem"
          @modal-invisible="isDeleteModalVisible = false"
        />

        <!-- Download files -->
        <!--feather-icon 
          icon="DownloadIcon" 
          size="18" 
          class="text-primary cursor-pointer ml-1" 
          @click="handleDownloadFiles(item)" 
          :id="'download-payemnt-button-'+item.key" 
        /-->
        <a
          v-if="item.fileURL"
          class="ml-1"
          :href="'https://uploadscdn.nectios.com/'+item.fileURL"
          target="_blank"
        >
          <feather-icon icon="DownloadIcon" size="20" />
        </a>
      </div>
    </template>
  </b-table>
</template>

<script>
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import moment from "moment";
import UserAvatar from "@core/components/user/UserAvatar.vue";
import { getCollectiveUrl } from "@core/utils/collective";
import UnshareItemModal from "@/@core/components/modal/UnshareItemModal.vue";

export default {
  components: {
    UserAvatar,
    UnshareItemModal,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    busy: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
			isModalEditOpened: false,
			isDeleteModalVisible: false,
			currentItem: null,
			shipment: {},
    }
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    fieldsList() {
      return [
        {
          key: "ref",
          label: this.$t("backoffice.shipments.fields.ref"),
          sortable: true,
        },
        {
          key: "sender",
          label: this.$t("backoffice.shipments.fields.sender"),
          sortable: true,
        },
        {
          key: "receiver",
          label: this.$t("backoffice.shipments.fields.receptor"),
          sortable: true,
        },
        {
          key: "freightmode",
          label: this.$t("backoffice.shipments.fields.freight-mode"),
          sortable: true,
        },
        {
          key: "incoterm",
          label: this.$t("backoffice.shipments.fields.incoterm"),
          sortable: true,
        },
        {
          key: "shippingdate",
          label: this.$t("backoffice.shipments.fields.shipping-date"),
          sortable: true,
        },
        {
          key: "deliverydate",
          label: this.$t("backoffice.shipments.fields.delivery-date"),
          sortable: true,
        },
        {
          key: "status",
          label: this.$t("backoffice.shipments.fields.status"),
          sortable: true,
        },
        {
          key: "user",
          label: this.$t("backoffice.shipments.fields.createdby"),
          sortable: true,
        },
        {
          key: "createdat",
          label: this.$t("backoffice.shipments.fields.createdat"),
          sortable: true,
        },
        {
          key: "actions",
          label: this.$t("backoffice.shipments.fields.actions"),
          sortable: true,
        },
      ];
    },
    showTotals() {
      return true;
    }
  },
  methods: {
    async fetchMembers(member) {
      const response = await this.$store.dispatch("getItems", {
        itemType: "communityMembers",
        forceAPICall: true,
        requestConfig: {
          page: 1,
          perPage: 10,
          username: member.username,
          getMinimalData: 1
        },
      });

      if (response.data?.length > 0) {
        const route = this.$router.resolve({
          name: "backoffice-members-details",
          params: {
            username: member.username,
            communityId: this.currentCollective.slug,
          },
        });
        window.open(route.href, "_blank");
        return;
      }
      const route = this.$router.resolve({
        name: "backoffice-members-details",
        params: {
          username: member.username,
          communityId: this.currentCollective.parentCollective.slug,
        },
      });
      window.open(route.href, "_blank");
    },
    translate(field) {
      // console.log('Trnaslating field:', field)
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        field
      );
    },
    timestampToTime(timestamp) {
      moment.locale(this.currentLocale);
      return moment(timestamp).format("DD MMM YYYY - HH:mm");
    },
    getStatus(shipment) {
      const statusPayment = {
        pending: this.$t("backoffice.members.pending"),
        succeeded: this.$t("backoffice.members.confirmed"),
        failed: this.$t("backoffice.members.confirmed"),
      };
      return statusPayment[shipment];
    },
    getCollectiveUrl(collective) {
      return `${getCollectiveUrl(collective)}/welcome`;
    },
    getTranslatedName(item) {
      if(!item){
        return '---';
      }
      return this.translate(item.name);
    },
    getColor(shipment) {
      const color = {
        pending: "light",
        succeeded: "light-success",
        failed: "light-danger",
      };
      return color[shipment];
    },
    getTransactionDate(date){
      if(!date){
        return '---';
      }
      return this.timestampToTime(date);
    },
    getFreightMode(freightMode) {
      const modes = {
        null: '--',
        0: 'Sea Freight',
        1: 'Air Freight',
        2: 'Road Freight',
        3: 'Rail Freight'
      };
      console.log('modes[freightMode]', modes[freightMode]);
      return modes[freightMode];
    },
    getIncoterm(freightPrepaidIncoterm, freightCollectIncoterm) {
      const prepaid = freightPrepaidIncoterm ? freightPrepaidIncoterm.toUpperCase() : null;
      const collect = freightCollectIncoterm ? freightCollectIncoterm.toUpperCase() : null;
      let label = '';
      if(prepaid){
        label += prepaid;
      }
      if(prepaid && collect){
        label += ' & ';
      }
      if(collect){
        label += collect;
      }
      return label;
    },
    getCurrencyLabel(currency) {
      if(!currency){
        return 'EUR (€)';
      }
      return currency.ISO + ' (' + currency.symbol + ')';
    },
		handleEdit(item) {
      console.log('handleEdit item', item);
			this.currentItem = { ...item };
			delete this.currentItem.key;
			delete this.currentItem.ref;
			delete this.currentItem.shippingDate;
			delete this.currentItem.deliveryDate;
			delete this.currentItem.freightMode;
			this.shipment = item;
			this.isModalEditOpened = true;
      //TODO: emit edit with currentItem
		},
		handleDelete(item) {
      console.log('handleDelete item', item);
			this.currentItem = { ...item };
			this.shipment = item;
			this.isDeleteModalVisible = true;
      console.log('this.currentItem', this.currentItem)
      console.log('Opening delete modal...')
		},
    async handleRemoveItem(executeDelete) {
      // console.log('handleRemoveItem...');
      const itemKey = this.currentItem?.key;
      // console.log('itemKey', itemKey);
      if (executeDelete === true) {
        // console.log('Deleting this shipment...');

        try {
          const itemData = {
            itemType: 'shipments',
            storedKey: "shipments",
            modelName: 'shipment',
            tableName: 'shipments',
            key: itemKey,
            modelKey: itemKey
          };
          // console.log('itemData', itemData)

          const deleteItemGenericResult = await this.$store.dispatch('deleteItemGeneric', {
            item: itemData,
            customName: "shipments",
            noSet: true,
          });
          // console.log('deleteItemGenericResult', deleteItemGenericResult)
          if(deleteItemGenericResult){
            // console.log('refrescando paginaaaa')
            window.location.reload();
          }

          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t('unshare.toast-messages.success', {
                item: this.$t('unshare.singular-items.shipment'),
              }),
              icon: 'CheckIcon',
              variant: 'success',
            },
          });
        } catch {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t('unshare.toast-messages.error', {
                item: this.$t('unshare.singular-items.shipment'),
              }),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        }

        this.$emit('load-items');
        this.$emit('close-modal', itemKey);
        this.isUnshareModalVisible = false;
			  this.currentItem = null;
        //TODO: hacer que se sepa si se ha eliminado bien y se ha quitado el elemento del state. Sino, refrescar pag
        
        // this.$router.push({
        //   name: "shipments",
        //   params: { communityId: this.$route.params.communityId },
        // });
      }
    },
		async deleteItem(key) {
      console.log('deleting item with key:', key)
			try {
				await this.$store.dispatch("editItem", {  // para que sea PUT
					item: {
						itemType: "deletePayment",
						requestConfig: {
							paymentKey: key,
						},
					},
				});
			} catch {}
			this.currentItem = null;
		},
		handleDownloadFiles(item){
			//console.log('this.hiddenLinks', this.hiddenLinks);
			//e.preventDefault();

			// if(!this.hiddenLinks[item]){
			// 	return 0;
			// }
			// this.hiddenLinks[item].forEach(url => {
			// 	console.log('url to open:', url);
    	// 		window.open(url);
			// });
		},
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include"; // Bootstrap includes
.totals {
  background-color: $primary;
}
.download-button {
  background-color: white !important;
  color: #28a9b8 !important;
  bshipment: none;
}
</style>
