<template>
  <section>
    <b-card v-if="paymentSelected" class="d-flex flex-column">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <h4>{{ $t('backoffice.shipments.table.title') }}</h4>
        <div @click="goBack">
          <feather-icon size="24" icon="XIcon" />
        </div>
      </div>
      <h5>{{ $t('backoffice.shipments.table.client') }}</h5>
      <b-link
        class="text-dark d-flex align-items-center"
        @click="fetchMembers(paymentSelected.paidByMember)"
      >
        <div>
          <user-avatar :user="paymentSelected.paidByMember" size="24" />
        </div>
        <div class="ml-50">
          <p
            v-if="paymentSelected.paidByMember"
            class="font-weight-extrabold mb-0"
          >
            {{
              `${paymentSelected.paidByMember.name} ${paymentSelected.paidByMember.surname}`
            }}
          </p>
          <span v-else class="text-muted">---</span>
        </div>
      </b-link>
      <hr />
      <h5>{{ $t('backoffice.shipments.table.space') }}</h5>
      <div
        class="mb-75 d-flex align-items-center justify-content-between w-100"
      >
        <b-link
          class="text-dark"
          :href="getCollectiveUrl(paymentSelected.community)"
          target="_blank"
        >
          <p class="mb-0">
            {{ translate(paymentSelected.community.name) }}
          </p>
          <p
            v-if="paymentSelected.community.headline"
            class="small mb-0 mt-n25 text-muted"
          >
            {{ translate(paymentSelected.community.headline) }}
          </p>
        </b-link>
        <b-badge
          pill
          :variant="getColorSpace(paymentSelected.community.mainType)"
          class="text-capitalize check-button"
        >
          {{ getStatusSpace(paymentSelected.community.mainType) }}
        </b-badge>
      </div>
      <hr />
      <h5>{{ $t('backoffice.shipments.table.data') }}</h5>
      <div class="d-flex align-items-center justify-content-between">
        <p class="mb-50 small">Data</p>
        <p class="mb-50">
          {{ timestampToTime(paymentSelected.createdAt) }}
        </p>
      </div>
      <div class="d-flex align-items-center justify-content-between">
        <p class="mb-50 small">Referència</p>
        <p class="mb-50">
          {{ paymentSelected.ref }}
        </p>
      </div>
      <div class="d-flex align-items-center justify-content-between">
        <p class="mb-50 small">Estat</p>
        <b-badge
          v-if="paymentSelected.status"
          pill
          :variant="getColor(paymentSelected.status)"
          class="text-capitalize mb-50 ml-1 check-button"
        >
          {{ getStatus(paymentSelected.status) }}
        </b-badge>
        <p v-else class="text-muted mb-50 ml-2 pl-75">---</p>
      </div>
    </b-card>
    <b-table
      v-else
      hover
      :busy="busy"
      :items="item.shipments"
      :fields="fieldsList"
      responsive
      :sticky-header="'calc(100vh - 270px)'"
      thead-class="d-none"
    >
      <!-- Column: Name -->
      <template #cell(userName)="{ item }">
        <b-media
          class="d-flex align-items-center ml-n25"
          style="min-width: 150px"
        >
          <template>
            <b-link class="text-dark d-flex" @click="getPaymentDetail(item)">
              <div class="mt-25 ml-n25">
                <user-avatar :user="item.paidByMember" size="24" />
              </div>
              <div class="ml-50">
                <p v-if="item.paidByMember" class="font-weight-extrabold mb-0">
                  {{ `${item.paidByMember.name} ${item.paidByMember.surname}` }}
                </p>
                <span v-else class="text-muted">---</span>
                <p class="mb-0">
                  {{ translate(item.community.name) }}
                </p>
              </div>
            </b-link>
          </template>
        </b-media>
      </template>
      <!-- Column: price -->
      <template #cell(price)="{ item }">
        <b-link class="text-dark d-flex" @click="getPaymentDetail(item)">
          <div
            class="w-100 h-100 d-flex flex-column align-items-end justify-content-center pr-25"
          >
            <p class="mb-0 font-weight-extrabold text-primary">
              {{ item.price.toFixed(2) }} {{ item.currency.symbol }}
            </p>
            <p class="mb-0 small text-muted">
              {{ timestampToTime(item.createdAt) }}
            </p>
          </div>
        </b-link>
      </template>
      <template #custom-foot="{ columns }">
        <b-td v-for="columnIndex in columns" :key="columnIndex" class="totals">
          <p
            v-if="columnIndex === 1"
            class="font-weight-extrabold mb-0 text-white font-medium-2"
          >
            {{ $t('backoffice.shipments.table.totals') }}
          </p>
          <div
            v-if="columnIndex === 2"
            class="w-100 h-100 d-flex align-items-center justify-content-end"
          >
            <p class="mb-0 font-weight-extrabold font-medium-2 text-white">
              {{ item.totals.price.toFixed(2) }}
              {{ item.shipments[0].currency.symbol }}
            </p>
          </div>
        </b-td>
      </template>
    </b-table>
  </section>
</template>

<script>
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import moment from "moment";
import UserAvatar from "@core/components/user/UserAvatar.vue";
import { getCollectiveUrl } from "@core/utils/collective";

export default {
  components: {
    UserAvatar,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    busy: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      paymentSelected: null,
    };
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    fieldsList() {
      return [
        {
          key: "userName",
          label: this.$t("backoffice.shipments.fields.username"),
          sortable: true,
        },
        {
          key: "price",
          label: this.$t("backoffice.shipments.fields.total"),
          sortable: true,
        },
      ];
    },
  },
  methods: {
    getPaymentDetail(shipment) {
      this.paymentSelected = shipment;
      this.$emit("isSpaceNameShown", false);
    },
    goBack() {
      this.paymentSelected = null;
      this.$emit("isSpaceNameShown", true);
    },
    getCollectiveUrl(collective) {
      return `${getCollectiveUrl(collective)}/welcome`;
    },
    async fetchMembers(member) {
      const response = await this.$store.dispatch("getItems", {
        itemType: "communityMembers",
        forceAPICall: true,
        requestConfig: {
          page: 1,
          perPage: 10,
          username: member.username,
          getMinimalData: 1
        },
      });

      if (response.data?.length > 0) {
        const route = this.$router.resolve({
          name: "backoffice-members-details",
          params: {
            username: member.username,
            communityId: this.currentCollective.slug,
          },
        });
        window.open(route.href, "_blank");
        return;
      }
      const route = this.$router.resolve({
        name: "backoffice-members-details",
        params: {
          username: member.username,
          communityId: this.currentCollective.parentCollective.slug,
        },
      });
      window.open(route.href, "_blank");
    },
    translate(field) {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        field
      );
    },
    timestampToTime(timestamp) {
      moment.locale(this.$store.state.locale.currentLocale);
      const format = this.paymentSelected ? "DD MMMM YYYY" : "DD MMM";
      return moment(timestamp).format(format);
    },
    getStatus(shipment) {
      const statusPayment = {
        pending: this.$t("backoffice.members.pending"),
        succeeded: this.$t("backoffice.members.confirmed"),
        failed: this.$t("backoffice.members.confirmed"),
      };
      return statusPayment[shipment];
    },
    getColor(shipment) {
      const color = {
        pending: "light",
        succeeded: "light-success",
        failed: "light-danger",
      };
      return color[shipment];
    },
    getStatusSpace(mainType) {
      if(!mainType){
        return 'Space';
      }
      const type = {
        Community: this.$t("collectives.items.Community"),
        Subcommunity: this.$t("collectives.items.Subcommunity"),
        Event: this.$t("collectives.items.Event"),
        Section: this.$t("collectives.items.Section"),
        Course: this.$t("collectives.items.Course"),
        Workspace: this.$t("collectives.items.Workspace"),
        Challenge: this.$t("collectives.items.Challenge"),
        Organization: this.$t("collectives.items.Organization"),
        Service: this.$t("collectives.items.Service"),
      };
      return type[mainType][0].toUpperCase() + type[mainType].substring(1);
    },
    getColorSpace(shipment) {
      const color = {
        0: "light",
        1: "light-warning",
        2: "warning",
        3: "light-success",
        4: "light-danger",
        5: "light-dark",
        6: "secondary",
        7: "light-info",
        8: "info",
        81: "dark",
        Community: "primary",
        Subcommunity: "light-info",
        Event: "danger",
        Section: "success",
        Course: "info",
        Workspace: "dark",
        Challenge: "secondary",
        Organization: "light-info",
        Service: "light-warning",
      };
      return color[shipment];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include"; // Bootstrap includes

.totals {
  background-color: $primary;
}
</style>
